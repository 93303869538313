import { SidenavItemInterface } from 'src/app/layout/side-nav/interfaces/side-nav-items.interface';

export const cmSidenavConfig: SidenavItemInterface[] = [
  {
    id: 'vehicles',
    label: 'vehicles',
    routerLink: '/vehicles',
    permissions: ['VIEW_VEHICLE'],
  },
  {
    id: 'features',
    label: 'features',
    routerLink: '/features',
    permissions: ['VIEW_FEATURE'],
  },
];
