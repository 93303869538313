import { SidenavItemInterface} from "src/app/layout/side-nav/interfaces/side-nav-items.interface";
import { AuthUserModel} from "src/app/vogo-core/models/AuthUser.model";

export class SidenavModel implements SidenavItemInterface {
  private routerLinkArray: string[];

  id: string;
  isActive? = false;
  expanded? = false;
  tooltip?: string;
  routerLink?: string;
  label?: string;
  icon?: string;
  permissions?: string | (string | string[])[];
  items: SidenavModel[] = [];
  parent?: SidenavModel;
  isShown = true;
  direction?: string;
  routerLinkExact?: boolean;

  constructor(item: SidenavItemInterface, parent?: SidenavModel) {
    this.id = item.id;
    this.expanded = item.expanded;
    this.isActive = item.isActive;
    this.icon = item.icon;
    this.label = item.label;
    this.routerLink = item.routerLink;
    this.routerLinkArray = this.initRouterLinkArray(item.routerLink ?? '/');
    this.tooltip = item.tooltip;
    this.permissions = item.permissions;
    this.parent = parent;
    this.direction = item.direction;
    this.routerLinkExact = item.routerLinkExact;
    item.items?.forEach((item: SidenavItemInterface) =>
      this.items.push(new SidenavModel(item, this)),
    );
  }

  /** Set Main links */
  setMainLinks(mainLinks: SidenavItemInterface[]): SidenavModel[] {
    this.items = mainLinks.map(
      (link: SidenavItemInterface) => new SidenavModel(link, this),
    );
    return this.items;
  }

  /** Set Sub Main links */
  setSubMainLinks(subItems: SidenavItemInterface[]): SidenavModel {
    this.items = this.items.concat(
      subItems.map((item) => new SidenavModel(item, this)),
    );
    return this;
  }

  /** Expand nav item, check if its link activate it and check parent too */
  toggleExpandItem(): void {
    if (this.routerLink) {
      this.isActive = true;
    }
    if (this.items.length) {
      this.expanded = true;
    }
    if (this.parent) {
      this.parent.toggleExpandItem();
    }
  }

  /**
   * Un expand all items and un activate them
   */
  unExpandAllItems(): void {
    this.expanded = false;
    this.isActive = false;
    this.items.forEach((item) => item.unExpandAllItems());
  }

  /**
   * check user permissions and hide not authorized sidenav links
   * @param user
   */
  checkPermissions(user: AuthUserModel | null | undefined): SidenavModel {
    if (!this.permissions || !this.permissions?.length) {
      this.isShown = true;
    } else {
      this.isShown = user ? user.can(this.permissions) : false;
    }
    for (let item of this.items ?? []) {
      item.checkPermissions(user);
    }
    this.isShown = this.items.length
      ? this.items.some((i: any) => i.isShown)
      : this.isShown;
    return this;
  }

  /** Check for current url and compare all sidenav items with it */
  activateItem(
    routerLink: string[],
    minRate = 0,
    parent?: SidenavModel,
  ): number {
    for (let item of this.items) {
      const matchedRate = item.getMatchRate(routerLink);

      if (matchedRate > minRate && item.isShown) {
        parent?.unExpandAllItems();
        item.toggleExpandItem();
        minRate = matchedRate;
        if (minRate === 1) {
          return minRate;
        }
      }

      if (item.items) {
        minRate = item.activateItem(routerLink, minRate, this);
        if (minRate === 1) {
          return minRate;
        }
      }
    }

    return minRate;
  }

  /**
   * Checking router link if is active when page reload by get link of the page and compare it to the routerLink of
   * current item
   * @param routerLink
   */
  extractMatchedRoutePieces(routerLink: string[]) {
    let index = 0;
    for (const piece of this.routerLinkArray) {
      if (routerLink[index++] !== piece) {
        return [];
      }
    }
    return this.routerLinkArray;
  }

  getMatchRate(routerLink: string[]) {
    return (
      this.extractMatchedRoutePieces(routerLink).length / routerLink.length
    );
  }

  private initRouterLinkArray(route: string) {
    route = route.startsWith('/') ? route : '/' + route;
    return route.split('/');
  }
}
