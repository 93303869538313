import { NgModule } from '@angular/core';
import {AuthenticationRoutingModule} from "src/app/applications/authentication/authentication.routing.module";

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * NOTE THAT THIS MODULE WILL BE LOADED IN THE APP.
 * DO NOT IMPORT ANYTHING IN HERE.
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

@NgModule({
  declarations: [],
  imports: [AuthenticationRoutingModule],
})
export class AuthenticationModule {
  constructor() {

  }
}
