import { Injectable } from '@angular/core';
import AuthUserModelInterface from "src/app/kernel/shared/interfaces/auth-user-model.interface";
import {BehaviorSubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$ = new BehaviorSubject<any>(undefined);

  constructor() { }


  getUser(): AuthUserModelInterface | undefined {
    return <AuthUserModelInterface>{can: (permissions: string | (string | string[])[]) => true};
  }

  logout(): void {
    return void 0 ;
  }
}
