import { NgModule } from '@angular/core';
import { FleetRoutingModule } from './fleet-routing.module';
import { SideNavService } from 'src/app/layout/side-nav/services/side-nav.service';
import { cmSidenavConfig } from './cmSidenav.config';

@NgModule({
  imports: [FleetRoutingModule,],
})
export class FleetModule {
  constructor(sideNavService:SideNavService){
    sideNavService.addSubMenuItems('fleet',cmSidenavConfig);
  }
}
