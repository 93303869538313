import { NgModule } from '@angular/core';
import {SideNavService} from "src/app/layout/side-nav/services/side-nav.service";
import {crmSidenavConfig} from "src/app/applications/crm/crmSidenav.config";
import {CrmRoutingModule} from "src/app/applications/crm/crm.routing.module";

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * NOTE THAT THIS MODULE WILL BE LOADED IN THE APP.
 * DO NOT IMPORT ANYTHING IN HERE.
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

@NgModule({
  declarations: [],
  imports: [CrmRoutingModule],
})
export class CrmModule {
  constructor(sideNavService: SideNavService) {
    sideNavService.addSubMenuItems('crm', crmSidenavConfig)
  }
}
