import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubscriptionRoutingModule } from './subscription-routing.module';
import { SideNavService } from 'src/app/layout/side-nav/services/side-nav.service';
import { subscriptionSidenavConfig } from './config/subscription-sidenav.config';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SubscriptionRoutingModule
  ]
})
export class SubscriptionModule {
  constructor(sideNavService: SideNavService) {
    sideNavService.addSubMenuItems('finance', subscriptionSidenavConfig)
  }
 }
