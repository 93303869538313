import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

const route: Route[] = [
  {
    path: 'vehicles',
    loadChildren: () =>
      import('./modules/vehicles/vehicles.module').then(
        ({ VehiclesModule }) => VehiclesModule
      ),
  },
  {
    path: 'features',
    loadChildren: () =>
      import('./modules/features/features.module').then(
        ({ FeaturesModule }) => FeaturesModule
      ),
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(route)],
  exports: [RouterModule],
})
export class FleetRoutingModule {}
