import { SidenavItemInterface } from 'src/app/layout/side-nav/interfaces/side-nav-items.interface';

export const settingSidenavConfig: SidenavItemInterface[] = [
  {
    id: 'operators',
    label: 'operators',
    routerLink: '/operators',
    permissions: ['VIEW_OPERATORS'],
  },
  {
    id:'roles',
    label:'roles',
    routerLink:'/roles',
    permissions: ['VIEW_ADMIN_ROLES'],
  }
];

export const driverSidenavConfig: SidenavItemInterface[] = [
  {
    id: 'drivers',
    label: 'drivers',
    routerLink: '/drivers',
    permissions: ['VIEW_DRIVER'],
  },
];
