export enum EAccessType {
  SetRegistrationCredentials = 0,
  VerifyRegistration = 1,
  VerifyCompanyRegistration = 2,
  CompleteRegistration = 3,
  CompleteCompanyRegistration = 4,
  VerifyResetPassword = 5,
  ConfirmResetPassword = 6,
  TFA = 7,
  FAT = 8
}