import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/**
 * NOTE THAT THIS FILE SHOULD ONLY CONTAIN LAZY LOADING ROUTS
 * EACH ROUTE SHOULD POINT TO A MODULE THAT CONTAINS ITS OWN ROUTES
 */
const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'driver-auth',
    loadChildren: () =>
      import('./modules/driver-on-boarding/driver-on-boarding.module').then(
        (m) => m.DriverOnBoardingModule
      ),
  },
  {
    path: 'operator-auth',
    loadChildren: () =>
      import('./modules/operator-on-boarding/operator-on-boarding.module').then(
        (m) => m.OperatorOnBoardingModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
