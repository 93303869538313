import { NgModule } from '@angular/core';
import {DashboardRoutingModule} from "src/app/applications/dashboard/dashboard-routing.module";

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * NOTE THAT THIS MODULE WILL BE LOADED IN THE APP.
 * DO NOT IMPORT ANYTHING IN HERE.
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */


@NgModule({
  declarations: [],
  imports: [DashboardRoutingModule],
  providers:[]
})
export class DashboardModule { }
