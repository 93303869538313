import { SidenavItemInterface } from 'src/app/layout/side-nav/interfaces/side-nav-items.interface';

export const journeySidenavConfig: SidenavItemInterface[] = [
  {
    id: 'station',
    label: 'stations',
    routerLink: '/station',
    permissions: ['VIEW_STATION']
  },
  {
    id: 'route',
    label: 'routes',
    routerLink: '/route',
    permissions: ['VIEW_ROUTE']
  },
  {
    id: 'trip',
    label: 'trips',
    routerLink: '/trip',
    permissions: ['VIEW_TRIP']
  },
];
