import { NgModule } from '@angular/core';
import { JourneyRoutingModule } from './journey-routing.module';
import {SideNavService} from "src/app/layout/side-nav/services/side-nav.service";
import {journeySidenavConfig} from "src/app/applications/journey/journeySidenav.config";

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * NOTE THAT THIS MODULE WILL BE LOADED IN THE APP.
 * DO NOT IMPORT ANYTHING IN HERE.
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
@NgModule({
  imports: [JourneyRoutingModule],
})
export class JourneyModule {
  constructor(sideNavService: SideNavService) {
    sideNavService.addSubMenuItems('journeys', journeySidenavConfig)
  }
}
