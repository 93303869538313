import {SidenavItemInterface} from "src/app/layout/side-nav/interfaces/side-nav-items.interface";

export const crmSidenavConfig: SidenavItemInterface[] = [
  {
    id: 'passengers',
    label: 'passengers',
    routerLink: '/passengers',
    permissions: ['VIEW_PASSENGERS'],
  },
  {
    id: 'companies',
    label: 'companies',
    routerLink: '/companies',
    permissions: ['VIEW_COMPANY'],
  },
  {
    id: 'employees',
    label: 'employeesOfCompanies',
    routerLink: '/employees',
    permissions: ['VIEW_EMPLOYEES'],
  },
];
