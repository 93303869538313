import { NgModule } from '@angular/core';
import { SideNavService } from 'src/app/layout/side-nav/services/side-nav.service';
import { settingSidenavConfig, driverSidenavConfig } from './cmSidenav.config';
import { CmRoutingModule } from './cm-routing.module';



@NgModule({
  declarations: [],
  imports: [
    CmRoutingModule
  ]
})
export class CmModule { 
  constructor(sideNavService:SideNavService){
    sideNavService.addSubMenuItems('drivers',driverSidenavConfig);
    sideNavService.addSubMenuItems('setting',settingSidenavConfig);
  }
}
